var tabBarId = 'what-students-create-section-tabs';
var allProjectsTabId = 'all-projects-tab';
var sparkathonProjectsTabId = 'sparkathon-projects-tab';
var sparkTankProjectsTabId = 'spark-tank-projects-tab';
export function init() {
    var tabBarElement = document.getElementById(tabBarId);
    if (!tabBarElement) {
        return;
    }
    var tabs = [
        document.getElementById(allProjectsTabId),
        document.getElementById(sparkathonProjectsTabId),
        document.getElementById(sparkTankProjectsTabId),
    ];
    var tabContents = tabs.map(function (tab) {
        var tabContentId = tab.getAttribute('data-tab-content-id');
        return document.getElementById(tabContentId);
    });
    tabs.forEach(function (tab) {
        tab.addEventListener('click', function () {
            var tabContentId = tab.getAttribute('data-tab-content-id');
            var tabContentElement = document.getElementById(tabContentId);
            tabs.forEach(function (t) { return t.classList.remove('active'); });
            tab.classList.add('active');
            tabContents.forEach(function (t) { return t.classList.add('d-none'); });
            tabContentElement.classList.remove('d-none');
        });
    });
}
